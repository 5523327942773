import { useState, useEffect, useRef } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import Image from "next/image";
import MenuIcon from "@/components/Icon/MenuIcon";
import CloseIcon from "@/components/Icon/CloseIcon";
import { useAuthValue } from "@/contexts/contextAuth";
import { setScrollHidden, checkDealNowPage } from "@/libs/utils";
import useOutsideClick from "@/hooks/useOutsideClick";

const Menu = () => {
  const btnRef = useRef<HTMLButtonElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const [showMenuModal, setShowMenuModal] = useState<boolean>(false);

  const router = useRouter();
  const slug: string = router.query.slug ? router.query.slug[0] : "";

  const { setIsSignin, setIsSignup, isSignin, isSignup } = useAuthValue();

  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [isShowResources, setIsShowResources] = useState<boolean>(false);
  const [isShowReourcesOnMobile, setIsShowResourcesOnMobile] =
    useState<boolean>(false);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useOutsideClick(modalRef, isShowResources, setIsShowResources, btnRef);

  return (
    <div
      className={`${scrollPosition > 56 && "fixed sm:relative"} top-0 w-full ${
        slug == "buy" || checkDealNowPage() ? "bg-white" : "bg-[#EFF0F6]"
      } ${isSignin || isSignup ? "z-50" : "z-60"}`}
    >
      <div className="w-full lg:max-w-[1140px] px-[8%] lg:px-0 mx-auto py-4">
        <div className="md:flex justify-between">
          <div className="flex justify-between items-center space-x-8">
            <Link className="cursor-pointer" href={`/`}>
              <Image
                width={134}
                height={24}
                src="/assets/logo.svg"
                alt="logo"
              />
            </Link>
            <div className="hidden md:flex text-xl font-normal text-[#333] space-x-6">
              <Link
                className={`"cursor-pointer" ${
                  slug == "sell" ? "underline" : ""
                }`}
                href={`/sell`}
              >
                Sell
              </Link>
              <Link
                className={`"cursor-pointer" ${
                  slug == "buy" ? "underline" : ""
                }`}
                href={`/buy`}
              >
                Buy
              </Link>
              <Link
                className={`"cursor-pointer" ${
                  slug == "dealnow" ? "underline" : ""
                }`}
                href={`/dealnow`}
              >
                DealNow
              </Link>
              <div className="relative">
                <button
                  className="flex items-center space-x-2"
                  onClick={() => setIsShowResources(!isShowResources)}
                  ref={btnRef}
                >
                  <div className="cursor-pointer">Resources</div>
                  <Image
                    width={15}
                    height={10}
                    className={`${isShowResources && "rotate-180"}`}
                    src="/assets/expand_icon.svg"
                    alt="null"
                  />
                </button>
                {isShowResources && (
                  <div
                    className="absolute left-0 top-[40px] bg-white w-[177px] flex flex-col px-[13px] py-[27px] border border-[#EFF0F6] space-y-5 z-50"
                    ref={modalRef}
                  >
                    {/* <Link
                      className="cursor-pointer hover:underline"
                      href={`/services`}
                    >
                      Services
                    </Link> */}
                    <Link
                      className="cursor-pointer hover:underline"
                      href={`/how-it-works`}
                    >
                      How it works
                    </Link>
                    <Link
                      className="cursor-pointer hover:underline"
                      href={`/blog`}
                    >
                      Blog
                    </Link>
                    <Link
                      className="cursor-pointer hover:underline"
                      href={`/pricing`}
                    >
                      Pricing
                    </Link>
                  </div>
                )}
              </div>
            </div>

            <MenuIcon
              className="block md:hidden"
              onClick={() => setShowMenuModal(true)}
            />
          </div>
          {showMenuModal && (
            <div
              className={`fixed ${
                scrollPosition > 56 && "top-[-56px]"
              } w-[80%] h-[550px] bg-white justify-center items-center overflow-x-hidden overflow-y-scroll inset-0 z-60 outline-none focus:outline-none border-1 border-2-gray-400 shadow-md px-8 mt-[52px] mx-[20%]`}
            >
              <div className="flex justify-end py-4">
                <CloseIcon
                  fill="#00b3de"
                  onClick={() => {
                    setShowMenuModal(false);
                    document.body.style.overflowY = "scroll";
                  }}
                />
              </div>
              <div>
                <Link
                  className="text-xl font-bold text-[#333] cursor-pointer"
                  href={`/sell`}
                  onClick={() => setShowMenuModal(false)}
                >
                  Sell
                </Link>
                <hr className="bg-black my-4" />
              </div>
              <div>
                <Link
                  className="text-xl font-bold text-[#333] cursor-pointer"
                  href={`/buy`}
                  onClick={() => setShowMenuModal(false)}
                >
                  Buy
                </Link>
                <hr className="bg-black my-4" />
              </div>
              <div>
                <Link
                  className="text-xl font-bold text-[#333] cursor-pointer"
                  href={`/dealnow`}
                  onClick={() => setShowMenuModal(false)}
                >
                  DealNow
                </Link>
                <hr className="bg-black my-4" />
              </div>
              <div>
                <div
                  className="flex items-center space-x-3"
                  onClick={() =>
                    setIsShowResourcesOnMobile(!isShowReourcesOnMobile)
                  }
                >
                  <div className="text-xl font-bold text-[#333] cursor-pointer">
                    Resources
                  </div>
                  <Image
                    width={15}
                    height={10}
                    className={`${isShowReourcesOnMobile && "rotate-180"}`}
                    src="/assets/expand_icon.svg"
                    alt="null"
                  />
                </div>
                {isShowReourcesOnMobile && (
                  <div>
                    <div className="my-4">
                      {/* <Link
                        className="text-lg font-normal text-[#333] cursor-pointer"
                        href={`/services`}
                        onClick={() => setShowMenuModal(false)}
                      >
                        Services
                      </Link> */}
                    </div>
                    <div className="my-4">
                      <Link
                        className="text-lg font-normal text-[#333] cursor-pointer"
                        href={`/how-it-works`}
                        onClick={() => setShowMenuModal(false)}
                      >
                        How it works
                      </Link>
                    </div>
                    <div className="my-4">
                      <Link
                        className="text-lg font-normal text-[#333] cursor-pointer"
                        href={`/blog`}
                        onClick={() => setShowMenuModal(false)}
                      >
                        Blog
                      </Link>
                    </div>
                    <div className="my-4">
                      <Link
                        className="text-lg font-normal text-[#333] cursor-pointer"
                        href={`/pricing`}
                        onClick={() => setShowMenuModal(false)}
                      >
                        Pricing
                      </Link>
                    </div>
                  </div>
                )}
                <hr className="bg-black my-4" />
              </div>

              <div className="w-[95%] ml-[5%] my-8 space-y-4">
                <button
                  className="w-full bg-white hover:opacity-80 text-sm text-[#00b3de] font-medium py-2 px-4 border border-[#00b3de] rounded"
                  onClick={() => {
                    setShowMenuModal(false);
                    setIsSignin(true);
                    setScrollHidden();
                  }}
                >
                  Sign in
                </button>
                <button
                  className="w-full bg-[#00b3de] hover:opacity-80 text-white text-base font-bold py-2 px-4 rounded-lg cursor-pointer"
                  onClick={() => {
                    setShowMenuModal(false);
                    setIsSignup(true);
                    setScrollHidden();
                  }}
                >
                  Create account
                </button>
              </div>
            </div>
          )}
          <div className="hidden md:flex space-x-6">
            <button
              className="bg-white hover:opacity-80 text-sm text-[#00b3de] font-medium py-2 px-4 border border-[#00b3de] rounded"
              onClick={() => {
                setIsSignin(true);
                setScrollHidden();
              }}
            >
              Sign in
            </button>
            <button
              className="bg-[#00b3de] hover:opacity-80 text-white text-base font-bold py-1 px-4 rounded-lg cursor-pointer"
              onClick={() => {
                setIsSignup(true);
                setScrollHidden();
              }}
            >
              Create account
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
